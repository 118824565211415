<template>
    <div
        class="toggle-container"
        :class="{ vertical, dark, disabled }"
        :style="{ '--tab-count': tabs.length }"
    >
        <div
            class="selected-toggle"
            :style="{
                transform: vertical ? `translateY(${selected * 100}%)` : `translateX(${selected * 100}%)`,
                height: vertical ? `${100 / tabs.length}%` : '100%',
                width: vertical ? '100%' : `${100 / tabs.length}%`
            }"
        />
        <Clickable
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ selected: selected === index }"
            class="tab"
            @click="select(tab, index)"
        >
            <slot :tab="tab" />
        </Clickable>
    </div>
</template>

<script>
import Clickable from '@/components/utility/clickable';

export default {
    name: 'Toggle',
    components: { Clickable },
    props: {
        value: { type: [String, Boolean], default: null },
        tabs: { type: Array, default: () => [true, false] },
        vertical: { type: Boolean, default: false },
        dark: { type: Boolean, default: false },

        disabled: { type: Boolean, default: false }
    },
    emits: ['update:value'],
    slots: ['default'],
    data() {
        return {
            selected: 0
        };
    },
    watch: {
        value: {
            handler(to) {
                if (to === undefined || to === null) return;
                this.selected = this.tabs.indexOf(to);
            },
            immediate: true
        }
    },
    methods: {
        select(tab, index) {
            if (this.disabled) return;
            this.selected = index;
            this.$emit('update:value', tab);
        }
    }
};
</script>

<style lang="less">

.toggle-container {
    position: relative;
    display: grid;
    background-color: var(--smoke);
    border: 1px solid var(--smoke);
    border-radius: 6px;
    grid-template-columns: repeat(var(--tab-count), 1fr);
    &.vertical {
        grid-template-columns: none;
        grid-template-rows: repeat(var(--tab-count), 1fr);
    }
    .tab {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0;
        z-index: 1;
        padding: 6px;
        margin: -1px;
        .txt-component.heading, .icon {
            transition: var(--smooth);
            color: var(--steel);
        }
        &:hover, &.selected {
            .txt-component.heading, .icon { color: var(--coal); }
        }
    }
    .selected-toggle {
        position: absolute;
        transition: var(--smooth);
        border-radius: 6px;
        background-color: #FFF;
        box-shadow: var(--shadow-base);
    }
    &.disabled {
        .tab {
            cursor: default;
            pointer-events: none;
            &:hover, &.selected {
                .txt-component.heading, .icon { color: var(--steel); }
            }
        }
    }
    &.dark {
        border: 0;
        background-color: rgba(255, 255, 255, 0.12);
        .selected-toggle {
            background-color: rgba(255, 255, 255, 0.5);
        }
        .tab {
            &:hover, &.selected {
                .txt-component.heading, .icon {
                    color: #FFF;
                    fill: #FFF;
                }
            }
        }
        &.disabled {
            .tab {
                .txt-component.heading, .icon {
                    color: rgba(255, 255, 255, 0.1);
                }
                &:hover, &.selected {
                    .txt-component.heading, .icon {
                        color: #FFF;
                        fill: #FFF;
                    }
                }
            }
        }
    }
}
</style>
